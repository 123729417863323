<!--
 * @Autor: syq
 * @Date: 2021-07-15 14:08:57
-->

<template>
  <div id="RowImg">
    <div class="item" v-for="(item,index) in data.mediaContent" :key="index" >
        <img :src="item.mediaContent[0].url" alt="" @click="toTheme($router, item, 'swipe', item.linkType,storecode)">
    </div>
  </div>
</template>

<script>
import { toTheme } from '../../../../utils/homeToTheme'
export default {
  props: { data:Object,storecode:String},
  data() {
    return {
      toTheme,
    }
  },
  methods: {},
}
</script>
<style lang="less" scoped>
#RowImg {
    display: flex;
    width: 100%;
    overflow: hidden;
    margin: 8px 0;
    padding: 0 4px;
    height: 93px;
    justify-content: space-around;
    .item{
      width: 0;
      background-color: #ffffff;
      flex-grow: 1;
      margin: 0 2px;
      img{
        width: 100%;
        height: 100%;
      }
  }
}
</style>
