/*
 * @Autor: syq
 * @Date: 2021-07-12 16:37:32
 */

import request from "../../../request/request";
import {stringify} from 'qs'
// 获取商品列表
const getItemList = async () =>
  request({
    url: `/item/getItemList`,
    method: "get",
  });
const homemeeage = async (storeCode) =>
  request({
    url: `/construct/getOneConstruct?qp-status-eq=1&qp-type-eq=1&qp-storeCode-eq=${storeCode}`,
    method: "get",
  });

// currentPage, brandId, classid, spuid
const queryTotalItemList = async (params) => {
  return request({
    url:`/item/getItemList?${stringify(params)}`,
    method: "get",
  })
};
export { getItemList, homemeeage, queryTotalItemList };
